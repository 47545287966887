import React, {useState} from "react";
import * as common from 'styles/common.module.scss';
import Accordion from "components/Accordion";
import * as styles from './PricingDetails.module.scss';

const PricingDetails = () => {

    const [isOpen, setIsOpen] = useState()
    const toggleOpen = () => setIsOpen(prevState => !prevState)

    return <Accordion
        className={styles.container}
        onClick={toggleOpen}
        isOpen={isOpen}
        controlled
        customTitleContainer={<div className={styles.buttonWrapper}><button className={common.button}>
            {isOpen ? 'Ukryj szczegółowe ceny' : 'Zobacz więcej opcji cenowych'}
        </button></div>}
        content={<ul className={styles.pricesList}>
            <li><span>Na 30 dni</span><span>50 zł</span></li>
            <li><span>Na 90 dni</span><span>80 zł</span></li>
            <li><span>Na 180 dni</span><span>120 zł</span></li>
            <li><span>Na 360 dni</span><span>200 zł</span></li>
        </ul>}
    />


}

export default PricingDetails